import React, { Component } from "react";
import "./ImageGrid.css";
import { ImgOverlay } from "image-overlay-react";
import "image-overlay-react/dist/index.css";

class ImageGrid extends Component {
  state = {
    loading: true,
    value: "09:00",
    history: [],
  };

  componentDidMount = () => {
    fetch("/history.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((myJson) => {
        console.log(myJson.arr);
        this.setState({ history: myJson.arr });
      });
  };
  render() {
    return (
      <div className="image-grid">
        {/*<img
            key={index}
            src={
              "https://daily-quote-images.s3.us-east-1.amazonaws.com/" +
              encodeURIComponent(item[0]) +
              ".jpg"
            }
            alt={item[1]}
        />*/}
        {this.state.history.map((item, index) => (
          <div style={{ margin: "10px" }} key={index}>
            <ImgOverlay
              imgSrc={
                "https://daily-quote-images.s3.us-east-1.amazonaws.com/" +
                encodeURIComponent(item[0]) +
                ".jpg"
              }
              bgColor="white"
              position="right"
              width="300px"
              height="300px"
              fSize="12px"
              fColor="gray"
            >
              {item[2]}
            </ImgOverlay>
          </div>
        ))}
      </div>
    );
  }
}

export default ImageGrid;
