import React, { Component } from "react";
import { notify } from "react-notify-toast";
import Spinner from "./Spinner";

export default class Landing extends Component {
  // A bit of state to give the user feedback while their email address is being
  // added to the User model on the server.
  state = {
    sendingEmail: false,
  };

  onSubmit = (event) => {
    event.preventDefault();
    this.setState({ sendingEmail: true });

    // Super interesting to me that you can mess with the upper and lower case
    // of the headers on the fetch call and the world does not explode.
    console.log("sending email");
    let h = 0;
    try {
      let date = new Date()
        .toJSON()
        .slice(0, 10)
        .replace(/-/g, "/");
      let h = new Date(`${date} ${this.props.time}:00`)
        .toISOString()
        .substring(11, 13);
      console.log(h);
    } catch (e) {
      console.log(e);
    } finally {
      let date = new Date()
        .toJSON()
        .slice(0, 10)
        .replace(/-/g, "/");
      h = new Date(`${date} ${this.props.time}:00`)
        .toISOString()
        .substring(11, 13);
      console.log(h);
      fetch(
        `https://b7zg9aa5if.execute-api.us-east-1.amazonaws.com/default/create_contact_email_node?email=${
          this.email.value
        }&time=${h}`
      )
        .then((data) => {
          // Everything has come back successfully, time to update the state to
          // reenable the button and stop the <Spinner>. Also, show a toast with a
          // message from the server to give the user feedback and reset the form
          // so the user can start over if she chooses.
          this.setState({ sendingEmail: false });
          this.form.reset();
          return data.json();
        })
        .then((data) => {
          console.log(data);
          if (data === "found") {
            notify.show("Your preferences are updated!");
          } else {
            notify.show(
              "Thanks for signing up! You'll start receiving emails."
            );
          }
        })
        .catch((err) => console.log(err));
    }
  };

  render = () => {
    // This bit of state provides user feedback in the component when something
    // changes. sendingEmail is flipped just before the fetch request is sent in
    // onSubmit and then flipped back when data has been received from the server.
    // How many times is the 'sendingEmail' variable used below?
    const { sendingEmail } = this.state;

    return (
      // A ref is put on the form so that it can be reset once the submission
      // process is complete.
      <form onSubmit={this.onSubmit} ref={(form) => (this.form = form)}>
        <div style={this.props.style}>
          <input
            className="input"
            type="email"
            name="email"
            ref={(input) => (this.email = input)}
            required
          />
          {/* 
            Putting the label after the input allows for that neat transition
            effect on the label when the input is focused.
          */}
          <label htmlFor="email" className="label">
            email
          </label>
        </div>
        <div>
          {/* 
            While the email is being sent from the server, provide feedback that
            something is happening by disabling the button and showing a 
            <Spinner /> inside the button with a smaller 'size' prop passed in.
          */}
          <button type="submit" className="btn" disabled={sendingEmail}>
            {sendingEmail ? (
              <Spinner size="lg" spinning="spinning" />
            ) : (
              "Subscribe"
            )}
          </button>
        </div>
      </form>
    );
  };
}
