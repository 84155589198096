import React, { Component } from "react";
import Notifications from "react-notify-toast";
import Quote from "./Quote.png";
import Landing from "./components/Landing";
import TimePicker from "./components/Timepicker";
import ImageGrid from "./ImageGrid";
import "./App.css";

export default class App extends Component {
  state = {
    loading: true,
    value: "09:00",
    history: [],
  };

  componentDidMount = () => {
    fetch("/history.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((myJson) => {
        console.log(myJson.arr);
        this.setState({ history: myJson.arr });
      });
  };

  render = () => {
    return (
      // The 'container' class uses flexbox to position and center its three
      // children: <Notifications />, <main> and <Footer />
      <div style={{ padding: "1em" }}>
        <Notifications />
        <div className="container">
          {/* 
          <Notifications > component from 'react-notify-toast'. This is the 
          placeholder on the dom that will hold all the feedback toast messages 
          whenever notify.show('My Message!') is called.
        */}
          <h1>Get Free Daily Motivational Quotes!</h1>
          <p style={{ fontSize: "1.5em", marginTop: "-5px" }}>
            Quotes will primarily be by notable entrepreneurs with a brief
            description of their achievements.
          </p>
          <br />
          {window.innerWidth < 480 ? (
            <div className="center">
              <TimePicker
                style={{ width: "60%" }}
                time={this.state.value}
                onSet={(t) => {
                  this.setState({ value: t.format24 });
                }}
                theme="Stellar"
                className="timepicker"
                placeholder="Start Time"
                amPmAriaLabel="Select AM/PM"
              />
              <br />
              <Landing time={this.state.value} />
            </div>
          ) : (
            <div className="center">
              <TimePicker
                style={{
                  display: "inline-block",
                  marginRight: "10px",
                  float: "left",
                  width: "100px",
                }}
                time={this.state.value}
                onSet={(t) => {
                  this.setState({ value: t.format24 });
                }}
                theme="Stellar"
                className="timepicker"
                placeholder="Start Time"
                amPmAriaLabel="Select AM/PM"
              />
              <Landing style={{ width: "20em" }} time={this.state.value} />
            </div>
          )}
          <br />
          {/*<div className="containerd">
            <p style={{ textAlign: "left", marginBottom: "-1em" }}>
              Past Quotes
            </p>
            {this.state.history.map((item, index) => {
              return (
                <blockquote cite={item[1]} key={index}>
                  {item[0]}
                </blockquote>
              );
            })}
          </div>*/}
          <div className="site-footer">
            {/*<a
              href="https://www.producthunt.com/posts/daily-motivational-quotes-2?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-daily&#0045;motivational&#0045;quotes&#0045;2"
              target="_blank"
            >
              <img
                src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=372454&theme=light"
                alt="Daily&#0032;Motivational&#0032;Quotes - Free&#0032;daily&#0032;emailed&#0032;entrepreneurial&#0032;quotes&#0032;at&#0032;any&#0032;time | Product Hunt"
                style={{ width: "250px", height: "54px" }}
                width="250"
                height="54"
              />
        </a>*/}
            <p style={{ fontSize: "0.8em" }}>
              <a
                style={{ color: "black" }}
                href="https://www.instagram.com/dailyentrepreneurialquote/"
              >
                Instagram
              </a>{" "}
              | Photos provided by{" "}
              <a style={{ color: "black" }} href="https://www.pexels.com/">
                Pexels
              </a>
            </p>
            <p className="love">
              Below are past quotes made with{" "}
              <span style={{ color: "#e25555" }}>&hearts;</span>
            </p>
          </div>
        </div>
        <ImageGrid />
      </div>
    );
  };
}
